//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import Image from "next/image";
import { Carousel } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";

//? Assets --------------------------------------------------------------------->
import "./CategoryBannerCarousel.scss";
import { useState } from "react";

export default function CategoryBannerCarousel(props) {
  const [imageLoaded, setImageLoaded] = useState({});
  // const onChange = (currentSlide) => {
  //   console.log(currentSlide);
  // }
  const onImageLoad = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };
  return (
    <div className=" marginBottom30 category-banner-carousel">
      {/* afterChange={onChange} */}
      <Carousel>
        {props?.data?.map((slide, index) => {
          const [imageSrc, setImageSrc] = useState(
            slide?.image + "%3Fwidth%3D440"
          );

          const handleImageError = () => {
            setImageSrc(slide?.image);
          };
          return (
            <Link
              href={slide?.link}
              key={index}
              className="width100 displayBlock"
            >
              {!imageLoaded[index] && (
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "#e6e4e4",
                    position: "relative",
                    // marginLeft: "5px",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    display: imageLoaded[index] ? "none" : "block",
                  }}
                />
              )}
              <LazyLoadImage
                // priority
                width={897}
                height={436}
                onError={handleImageError}
                // onLoad={() => onImageLoad(index)}
                src={imageSrc}
                alt={slide?.link}
                className="CategoryBanner"
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
                loading="lazy"
              />
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
}
