//? Library Imports ------------------------------------------------------------>
// import Image from "next/image";
import { Card, Image } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";

//? Assets --------------------------------------------------------------------->
import "./SubCategoryCard.scss";
import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import { useState } from "react";

export default function SubCategoryCard({ catImage, cat, userAgent }) {
  const { isDesktop } = MediaQueryHook();
  // const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    catImage + `${isDesktop ? "%3Fwidth%3D640" : "%3Fwidth%3D330"}`
  );
  const handleImageError = () => {
    setImageSrc(catImage);
  };
  // const onImageLoad = () => {
  //   setImageLoaded(true);
  // };
  return (
    <div style={{ margin: isDesktop ? "0px 0px" : "0px 4px 5px 4px" }}>
      <Card
        hoverable
        className="SubCategoryCard"
        style={{ margin: isDesktop ? "0px 0px" : "0px 4px 5px 4px" }}
        cover={
          <>
            {/* {!imageLoaded && (
              <div
                style={{
                  width: "100%",
                  height: isDesktop ? "460px" : "170px",
                  backgroundColor: "#e6e4e4",
                  position: "relative",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  display: imageLoaded ? "none" : "block",
                }}
              />
            )} */}
            <LazyLoadImage
              width="100%"
              height="auto"
              // onLoad={onImageLoad}
              onError={handleImageError}
              src={imageSrc}
              alt={catImage ? catImage?.split("/").pop() : "category image"}
              preview={false}
              className="CategoryImage"
            // loading="lazy"
            />
          </>
          // <Image
          //   priority
          //   width={323}
          //   height={487}
          //   src={catImage}
          //   alt="Category"
          //   className="CategoryImage"
          //   style={{
          //     width: "100%",
          //     height: "auto",
          //     objectFit: "cover",
          //   }}
          // />
        }
      />
    </div>
  );
}
