import Link from "next/link";
import { useState } from "react";
import { Col, Row, Typography, Tooltip } from "antd";

import "./SubCategoryTabs.scss";

const { Text } = Typography;

export const SubCategoryTabs = (props) => {
  const [viewAll, setViewAll] = useState(false);
  const [tabs, setTabs] = useState([...props?.data.slice(0, 15)]);

  return (
    <Row className="SubCategoryTabs">
      <Col span={24} className="marginBottom10" style={{ textAlign: "center" }}>
        <Text className="font20Bold">Category</Text>
      </Col>

      <Col span={24}>
        <Row>
          {tabs?.map((item, index) => (
            <Col lg={3} md={6} key={index}>
              <Link href={item?.handle}>
                <Tooltip
                  overlayClassName="custom-tooltip"
                  title={item?.title ? item?.title : "N/A"}
                >
                  {/* Wrap the tab content with Tooltip */}
                  <Col
                    key={index}
                    // className="hoverEffect"
                    className="tab"
                  >
                    <Text className="ant-typography">
                      {item?.title ? item?.title : "N/A"}
                    </Text>
                  </Col>
                </Tooltip>
              </Link>
            </Col>
          ))}

          {props?.data?.length > 15 && !viewAll && (
            <Col lg={3} md={6}>
              <Col
                // className="hoverEffect"
                className="view-all-tab"
                onClick={() => {
                  setViewAll(true);
                  setTabs(props?.data);
                }}
              >
                View All
              </Col>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
