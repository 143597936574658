
//? Library Imports ------------------------------------------------------------>
// import { useSelector } from "react-redux";
// import { useEffect, useState } from "react";
import { Row, Col, Tabs, Typography } from "antd";
import Link from "next/link";

//? Assets --------------------------------------------------------------------->
import "./FilterByGenderSection.scss";
// import arrowLeft from "../../../../assets/svg/arrowLeft.svg";
// import arrowRight from "../../../../assets/svg/arrowRight.svg";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

//? Modules -------------------------------------------------------------------->
import SubCategoryCard from "./SubCategoryCard/SubCategoryCard";
import { SubCategoryTabs } from "./SubCategoryTabs/SubCategoryTabs";
import CategoryBannerCarousel from "./CategoryBannerCarousel/CategoryBannerCarousel";
import ProductsCarousel from "@/components/shared/ProductsCarousel/ProductsCarousel";
import { useEffect, useState } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function FilterByGenderSection(props) {
  // const [loading, setLoading] = useState(true);

  const { isDesktop, isTablet, isMobile } = props?.userAgent;

  // useEffect(() => {
  //   if (props?.data?.categories?.length) {
  //     setLoading(false);
  //   }
  // }, [props?.data?.categories]);

  const SlickNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          padding: "16px 20px",
          right: "38px",
          background: "#ffffff",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px 0px 0px 5px",
          border: "1px solid black",
        }}
        onClick={onClick}
      >
        <span>
          <MdArrowForwardIos
            style={{ color: "#000000", zIndex: 1, fontSize: "16px" }}
          />
        </span>
      </div>
    );
  };

  const SlickPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          padding: "16px 20px",
          background: "#ffffff",
          left: "40px",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "0px 5px 5px 0px",
          border: "1px solid black",
        }}
        onClick={onClick}
      >
        <span>
          <MdArrowBackIos
            style={{
              color: "#000000",
              zIndex: 1,
              fontSize: "16px",
            }}
          />
        </span>
      </div>
    );
  };

  // let noOfSlides = props?.data?.categories?.length > 5 ? true : false;

  const settings = {
    speed: 500,
    swipe: true,
    dots: false,
    // autoplay: true,
    // infinite: true,
    slidesToScroll: 1,
    arrows: false,
    //autoplayspeed: 1500,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
    slidesToShow: !isDesktop ? 1 : 4,

    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 4,
    //       // arrows: false,
    //     },
    //   },
    //   {
    //     breakpoint: 800,
    //     settings: {
    //       slidesToShow: 3,
    //       // arrows: false,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       // arrows: false,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       // arrows: false,
    //     },
    //   },
    // ],
  };

  const catsItems = props?.data?.categories?.map((item, index) => ({
    key: index,
    label: item?.category_name?.replace("CLOTHING", "").trim(),
    children: (
      <Row key={index}>
        <Col span={24}>
          <Row align="middle" justify="center">
            {(isMobile || isTablet) && (
              <Col span={24}>
                <CategoryBannerCarousel data={item?.bannerList} />
              </Col>
            )}
            <Row
              className={
                !isDesktop
                  ? "subcategory-cards-container subcategory-cards-mobile-container width100"
                  : "subcategory-cards-container width100 gap1000"
              }
              gutter={[10]}
            >
              {item?.sub_category_cards?.map((cat, index) => {
                return (
                  <Col span={isDesktop ? 6 : 11} key={index}>
                    {/* {loading ? (
                      <CustomSkeletonBox width="300px" height={400} />
                    ) : ( */}
                    <Link href={cat?.handle ? cat?.handle : "#"}>
                      <SubCategoryCard
                        catImage={cat?.image}
                        userAgent={props?.userAgent}
                        key={index}
                        cat={cat}
                      />
                    </Link>
                    {/* )} */}
                  </Col>
                );
              })}
            </Row>
          </Row>
        </Col>

        {isDesktop && (
          <Col span={24}>
            <SubCategoryTabs data={item?.sub_category_tabs} />
          </Col>
        )}

        {isDesktop && (
          <Col span={24}>
            <Col span={24} style={{ textAlign: "center" }}>
              <Text className="font20Bold main-heading">New Arrival</Text>
            </Col>
            <ProductsCarousel
              categoryName="New Arrival"
              type={"homepage"}
              data={item?.new_arrivals}
              userAgent={props?.userAgent}
            />
          </Col>
        )}
      </Row>
    ),
  }));

  return (
    <Row
      align="middle"
      justify="center"
      className={
        isDesktop
          ? "FilterByGenderSection"
          : "FilterByGenderSection by-gender-carousel by-gender-carousel-mobile marginTop20 "
      }
    >
      <Col
        span={24}
        style={{
          textAlign: "left",
          background: "#f2f3f7",
          padding: isDesktop && "0px auto 20px auto",
        }}
        className="sectionHeadingContainer"
      >
        {/* {isDesktop && (
          <Text
            level={5}
            style={{
              color: "#1E1E1E",
              fontSize: "24px",
              fontWeight: "700",
              position: "relative",
              top: "60px",
              left: "10px",
              zIndex: 1,
              width: "50%",
              margin: "0px",
            }}
            className="sectionHeadingBold textLeft"
          >
            {props?.data?.main_title ? props?.data?.main_title : null}
          </Text>
        )} */}
        <Col>
          {props?.data?.main_title ? (
            <h2 className="filter-gender-title sectionHeadingBold font25Bold textLeft">
              {props?.data?.main_title ? props?.data?.main_title : "N/A"}
            </h2>
          ) : null}
          <Tabs defaultActiveKey="1" items={catsItems} size={"large"} />
        </Col>
      </Col>
    </Row>
  );
}
